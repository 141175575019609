// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ID from "../../../../../libs/ID.res.js";
import * as React from "react";
import * as Checkbox from "../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderAlertModal from "../../../../../styleguide/modals/ProviderAlertModal/ProviderAlertModal.res.js";
import * as ProviderSelectionTileScss from "./ProviderSelectionTile.scss";

var css = ProviderSelectionTileScss;

function ProviderSelectionTile(props) {
  var checked = props.checked;
  var __className = props.className;
  var provider = props.provider;
  var className = __className !== undefined ? __className : "";
  var match = React.useState(function () {
        return "Hidden";
      });
  var setProviderModalVisibility = match[1];
  var userSetMaxProviders = props.selectedProviders.length >= 10 && props.userRole === "User" && !checked;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("label", {
                      children: [
                        JsxRuntime.jsx(Checkbox.make, {
                              id: "Provider-" + (ID.toString(provider.id) + "-RadioTile"),
                              size: "SM",
                              checked: checked,
                              disabled: userSetMaxProviders,
                              visuallyDisabled: userSetMaxProviders,
                              onChange: props.onToggle
                            }),
                        JsxRuntime.jsx("img", {
                              className: css.logo,
                              alt: provider.name,
                              src: provider.logo
                            }),
                        JsxRuntime.jsx("p", {
                              children: provider.name,
                              className: css.providerName
                            })
                      ],
                      className: Cx.cx([
                            css.container,
                            className
                          ]),
                      onClick: (function (param) {
                          setProviderModalVisibility(function (param) {
                                return "Shown";
                              });
                        })
                    }),
                userSetMaxProviders && match[0] === "Shown" ? JsxRuntime.jsx(ProviderAlertModal.make, {
                        close: (function () {
                            setProviderModalVisibility(function (param) {
                                  return "Hidden";
                                });
                          })
                      }) : null
              ]
            });
}

var make = ProviderSelectionTile;

export {
  css ,
  make ,
}
/* css Not a pure module */
