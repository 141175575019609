// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../components/Button/Button.res.js";
import * as Control from "../../components/Control/Control.res.js";
import * as IconClose from "../../icons/IconClose.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProviderAlertModalScss from "./ProviderAlertModal.scss";

var css = ProviderAlertModalScss;

function ProviderAlertModal(props) {
  var close = props.close;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(Control.make, {
                            className: css.closeOverlayButton,
                            onClick: (function (param) {
                                close();
                              }),
                            children: JsxRuntime.jsx(IconClose.make, {
                                  title: "Close",
                                  size: "MD",
                                  color: "Gray"
                                })
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Limit Reached",
                                    className: css.providerAlertModalMessageText
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: "You can only select up to 10 providers. Please deselect at least 1 provider, if you prefer a different provider.",
                                    className: css.providerAlertModalParagraph
                                  }),
                              JsxRuntime.jsx(Button.make, {
                                    size: "SM",
                                    color: "Gray",
                                    className: css.cancelButton,
                                    onClick: (function (param) {
                                        close();
                                      }),
                                    children: "Close"
                                  })
                            ],
                            className: css.providerAlertModalInformation
                          })
                    ],
                    className: css.providerAlertModalContainer
                  }),
              className: css.providerAlertModalMessageOverlay
            });
}

var make = ProviderAlertModal;

export {
  css ,
  make ,
}
/* css Not a pure module */
